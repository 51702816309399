<template>
  <div class="lg:flex lg:space-x-6 py-5">
    <router-link :to="{name: 'DocumentDetail', params: {id: document.id, slug: document.slug}}" :title="document.title">
      <div class="lg:w-60 w-full h-40 overflow-hidden rounded-lg relative shadow-sm">
        <img :src="document.thumbnail ? document.thumbnail.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-1.jpg'" alt="" class="w-full h-full absolute inset-0 object-cover">
      </div>
    </router-link>

    <div class="flex-1 lg:pt-0 pt-4">
      <router-link :to="{name: 'DocumentDetail', params: {id: document.id, slug: document.slug}}" :title="document.title" class="text-xl font-semibold line-clamp-2">
        {{ document.title }}
      </router-link>
      <p class="line-clamp-2 pt-1">
        {{ cutString(document.content) }}
      </p>

      <div class="flex items-center pt-3">
        <div class="flex items-center">
          <ion-icon name="eye-outline" class="text-xl mr-2 md hydrated" role="img" aria-label="thumbs up outline"></ion-icon>{{ numberFormat(document.views) }}
        </div>
        <div class="flex items-center mx-4">
          <ion-icon name="alarm-outline" class="text-xl mr-2 md hydrated" role="img" aria-label="thumbs up outline"></ion-icon>{{ timeago(document.created_at) }}
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import {cutString, numberFormat, stripTags, timeago} from "../../core/services/utils.service";

export default {
  name: "DocumentItem",
  props: {
    document: {
      type: Object,
      default() {
        return {
          title: ""
        };
      }
    }
  },
  methods: {
    cutString(x) {
      return cutString(stripTags(x), 150);
    },
    timeago(x) {
      return timeago(x);
    },
    numberFormat(x) {
      return numberFormat(x);
    },
  }
}
</script>
