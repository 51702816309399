<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="lg:px-20">
        <div class="card p-3" v-if="documents.data.length">
          <div class="divide-y">
            <DocumentItem v-for="document in documents.data" :document="document" :key="'document' + document.id"/>
          </div>

          <div class="mt-4">
            <v-pagination
                class="justify-content-center"
                v-if="documents.paginatorInfo.lastPage > 1"
                v-model="documents.paginatorInfo.currentPage"
                :pages="documents.paginatorInfo.lastPage"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="loadDocuments"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import DocumentItem from "./DocumentItem";
import {changePageTitle} from "../../core/services/utils.service";

export default {
  name: 'DocumentList',
  components: {
    VPagination,
    DocumentItem
  },
  data() {
    return {
      documents: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadDocuments() {
      let query = `query($page: Int) {
        documents(first: 10, page: $page, orderBy: [{column: "id", order: DESC}], where: {AND: [{column: "status", value: 1}]}) {
          data {
            id
            slug
            title
            content
            views
            created_at
            thumbnail {
              url
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.documents.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.documents) {
              this.documents = data.data.documents;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    }
  },
  mounted() {
    this.loadDocuments();
    changePageTitle("Tư liệu âm nhạc");
  }
}
</script>
